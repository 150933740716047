import { Button, Input, Layout, Row, Space, Steps, Switch, Tag } from 'antd';
import type { ValueAndLabelData } from 'egenie-utils';
import { egeniePcTheme } from 'egenie-common';
import { Provider, observer } from 'mobx-react';
import React from 'react';
import { GoodsWrapper } from '../../utils';
import styles from './index.less';
import { Store } from './store';
import { ENUM_CLOTHES_MATERIAL_TYPE } from './constants';

const baseGap: [number, number] = [
  16,
  16,
];

const Left = observer(({
  store: {
    uniqueCode,
    uniqueCodeRef,
    handleUniqueCode,
    handleUniqueCodePressEnter,
    qualityTestingData,
    paramsConfig,
    handleParamsConfigChange,
    isParamsConfigLoading,
  },
}: { store: Store; }) => {
  return (
    <>
      <header className={styles.titleContainer}>
        <span className={styles.tag}>
          质检
        </span>
      </header>
      <div className={styles.leftContainer}>
        <section>
          <div className={styles.label}>
            请扫描唯一码
          </div>
          <Input
            allowClear
            autoComplete="off"
            autoFocus
            className={styles.input}
            onChange={handleUniqueCode}
            onPressEnter={(event: any) => handleUniqueCodePressEnter(event.target.value)}
            placeholder="请扫描唯一码"
            ref={uniqueCodeRef}
            size="large"
            value={uniqueCode}
          />
        </section>
        <GoodsWrapper
          data={qualityTestingData}
          style={{ marginTop: egeniePcTheme.spacing['egenie-spacing-sm'] }}
        />
        <div className={styles.paramsContainer}>
          <Switch
            checked={paramsConfig.qualityPrint}
            checkedChildren="关闭自动打印扫描唯一码"
            loading={isParamsConfigLoading}
            onChange={(qualityPrint) => handleParamsConfigChange({ qualityPrint })}
            unCheckedChildren="开启自动打印扫描唯一码"
          />
        </div>
        <div className={styles.paramsContainer}>
          <Switch
            checked={paramsConfig.qualityPrintReaSon}
            checkedChildren="关闭质检次品打印次品原因"
            loading={isParamsConfigLoading}
            onChange={(qualityPrintReaSon) => handleParamsConfigChange({ qualityPrintReaSon })}
            unCheckedChildren="开启质检次品打印次品原因"
          />
        </div>
      </div>
    </>
  );
});

const Right = observer(({ store }: { store: Store; }) => {
  const {
    qualityItemData,
    otherProblem,
    handleOtherProblemChange,
    showTestingView,
    clothesMaterialType,
    handleCottonChange,
  } = store;
  return (
    <div className={styles.right}>
      <Steps
        direction="vertical"
        size="small"
      >
        <Steps.Step
          description={(
            <Space
              size={baseGap}
              wrap
            >
              <Tag
                color={clothesMaterialType === ENUM_CLOTHES_MATERIAL_TYPE.cotton.value ? 'error' : 'default'}
                onClick={() => handleCottonChange(ENUM_CLOTHES_MATERIAL_TYPE.cotton.value)}
              >
                {ENUM_CLOTHES_MATERIAL_TYPE.cotton.label}
              </Tag>
              <Tag
                color={clothesMaterialType === ENUM_CLOTHES_MATERIAL_TYPE.notCotton.value ? 'error' : 'default'}
                onClick={() => handleCottonChange(ENUM_CLOTHES_MATERIAL_TYPE.notCotton.value)}
              >
                {ENUM_CLOTHES_MATERIAL_TYPE.notCotton.label}
              </Tag>
            </Space>
          )}
          status="process"
          title={(
            <Row align="middle">
              <span style={{ color: egeniePcTheme.color['egenie-highlight-color'] }}>
                *
              </span>
              衣服类型&nbsp;&nbsp;
              <span style={{ color: egeniePcTheme.color['egenie-highlight-color'] }}>
                (必填)
              </span>
            </Row>
          )}
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.BasicProductCheckEnum}
              store={store}
            />
          )}
          status="process"
          subTitle="(开袋、核对吊牌信息/领标/尺码标/水洗唛)"
          title="信息核对"
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.OutwardAppearanceEnum}
              store={store}
            />
          )}
          status="process"
          subTitle="(链接处用手掌撑起，确认是否开线，检查里料是否溢出；展开衣物时，检查刺激性异味)"
          title="开线/里料/刺激异味"
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.WormOutOrFluidEnum}
              store={store}
            />
          )}
          status="process"
          subTitle="(展开衣物，解开扣子/拉链，肉眼观察背部，内衬，衣摆，衣领，袖档位置)"
          title="破损/脏污"
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.OtherEnum}
              store={store}
            />
          )}
          status="process"
          subTitle={(
            <>
              (
              <span className={styles.label}>
                口袋:&nbsp;
              </span>
              逐一口袋翻开,伸手确认是否有漏洞及异物;&nbsp;
              <span className={styles.label}>
                门襟:&nbsp;
              </span>
              逐一口袋翻开,伸手确认是否有漏洞及异物;&nbsp;
              <span className={styles.label}>
                Logo贴:&nbsp;
              </span>
              展开衣物,看图片核对衣Logo;&nbsp;
              <span className={styles.label}>
                拉链:&nbsp;
              </span>
              拉链逐一来回拉3次;&nbsp;
              <span className={styles.label}>
                扣子:&nbsp;
              </span>
              逐一解开扣子并扣回)
            </>
          )}
          title="口袋/门襟/Logo贴/拉链/扣子"
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.HatOrOxTendonEnum}
              store={store}
            />
          )}
          status="process"
          subTitle={(
            <>
              (
              <span className={styles.label}>
                帽子:&nbsp;
              </span>
              帽子展开,反向翻出,观察连接处和边缘含毛领;&nbsp;
              <span className={styles.label}>
                牛筋绳:&nbsp;
              </span>
              用手掌撑开牛筋绳2次)
            </>
          )}
          title="帽子/牛筋抽绳"
        />
        <Steps.Step
          description={(
            <TagItems
              data={qualityItemData.PackOrBarcodeEnum}
              store={store}
            />
          )}
          status="process"
          subTitle="(衣物折叠放入包装袋后，贴条)"
          title="包装袋/条码"
        />
        <Steps.Step
          description={(
            <Input
              allowClear
              disabled={!showTestingView}
              maxLength={50}
              onChange={handleOtherProblemChange}
              size="large"
              value={otherProblem}
            />
          )}
          status="process"
          title="其它问题"
        />
      </Steps>
    </div>
  );
});

const BtnContainer = observer(({
  store: {
    handleReset,
    handleQualityTesting,
    qualityTestingData,
    isTesting,
    disabledQualityGoods,
    disabledShortageGoods,
  },
}: { store: Store; }) => {
  return (
    <div className={styles.btnContainer}>
      <Space size="middle">
        <Button onClick={handleReset}>
          取消
        </Button>
        <Button
          disabled={disabledQualityGoods}
          icon={(
            <i
              className="icon-sms_tkcg"
              style={{ fontSize: 16 }}
            />
          )}
          loading={isTesting}
          onClick={() => handleQualityTesting(true, qualityTestingData.uniqueCode)}
          type="primary"
        >
          &nbsp;正品
        </Button>
        <Button
          danger
          disabled={disabledShortageGoods}
          icon={(
            <i
              className="icon-cp"
              style={{ fontSize: 16 }}
            />
          )}
          loading={isTesting}
          onClick={() => handleQualityTesting(false, qualityTestingData.uniqueCode)}
          type="primary"
        >
          &nbsp;次品
        </Button>
      </Space>
    </div>
  );
});

const TagItems = observer(({
  data,
  store: {
    selectQualityItem,
    handleItemClick,
  },
}: { store: Store; data: ValueAndLabelData; }) => {
  return (
    <Space
      size={baseGap}
      wrap
    >
      {
        data.map((item) => (
          <Tag
            color={selectQualityItem.includes(item.value) ? 'error' : 'default'}
            key={item.value}
            onClick={() => handleItemClick(item.value)}
          >
            {item.label}
          </Tag>
        ))
      }
    </Space>
  );
});

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Provider store={store}>
      <Layout className={styles.mainContainer}>
        <Layout.Sider
          theme="light"
          width={416}
        >
          <Left store={store}/>
        </Layout.Sider>
        <Layout.Content>
          <Right store={store}/>
        </Layout.Content>
        <BtnContainer store={store}/>
      </Layout>
    </Provider>
  );
}

