import type { InputRef } from 'antd';
import { Modal, message } from 'antd';
import type { ValueAndLabelData, BaseData } from 'egenie-utils';
import { printHelper, objToDict } from 'egenie-common';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import React from 'react';
import { playVoice, voiceData } from '../../utils';
import { UNICODE_BELONG_TYPE } from './types';
import type { QualityItemData, QualityTestingData, ParamsConfig } from './types';

export class Store {
  constructor() {
    this.getQualityItem();
    this.getParamsConfig();
  }

  @observable public isParamsConfigLoading = false;

  @observable public clothesMaterialType: number = undefined;

  @action public handleCottonChange = (clothesMaterialType: number): void => {
    this.clothesMaterialType = clothesMaterialType;
  };

  @observable public paramsConfig: ParamsConfig = {
    qualityPrint: false,
    qualityPrintReaSon: false,
  };

  @action public getParamsConfig = () => {
    this.isParamsConfigLoading = true;
    request<BaseData<ParamsConfig>>({ url: '/api/cloud/baseinfo/rest/param/config/query' })
      .then((info) => {
        this.paramsConfig.qualityPrint = Boolean(info?.data?.qualityPrint);
        this.paramsConfig.qualityPrintReaSon = Boolean(info?.data?.qualityPrintReaSon);
      })
      .finally(() => this.isParamsConfigLoading = false);
  };

  @action public handleParamsConfigChange = (data: Partial<ParamsConfig>): void => {
    this.isParamsConfigLoading = true;
    request({
      url: '/api/cloud/baseinfo/rest/param/config/save',
      method: 'post',
      data,
    })
      .then(() => {
        if ('qualityPrint' in data) {
          this.paramsConfig.qualityPrint = Boolean(data.qualityPrint);
        }

        if ('qualityPrintReaSon' in data) {
          this.paramsConfig.qualityPrintReaSon = Boolean(data.qualityPrintReaSon);
        }
      })
      .finally(() => this.isParamsConfigLoading = false);
  };

  @observable public qualityItemData: {[key: string]: ValueAndLabelData; } = {
    BasicProductCheckEnum: [],
    OutwardAppearanceEnum: [],
    HatOrOxTendonEnum: [],
    PackOrBarcodeEnum: [],
    WormOutOrFluidEnum: [],
    OtherEnum: [],
  };

  @observable public qualityTestingData: QualityTestingData = {};

  @computed
  public get showTestingView(): boolean {
    return !_.isEmpty(this.qualityTestingData);
  }

  @observable public uniqueCode = '';

  public uniqueCodeRef: React.RefObject<InputRef> = React.createRef();

  @action public handleUniqueCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.uniqueCode = event.target.value;
  };

  @action public handleUniqueCodePressEnter = (uniqueCode: string) => {
    this.handleClearState();

    if (!uniqueCode) {
      playVoice(voiceData.请输入唯一码);
      return;
    }

    request<BaseData<QualityTestingData>>({
      url: '/api/cloud/wms/rest/quality/cloud/queryUnique',
      method: 'POST',
      data: {
        uniqueCode,
        tempId: 0,
        tempType: '27',
      },
    })
      .then((info) => {
        this.qualityTestingData = info.data || {};
        this.clothesMaterialType = info.data?.cotton;

        if (info?.data?.printData && this.paramsConfig.qualityPrint) {
          printHelper.toggleToLodop();
          printHelper.print({
            preview: false,
            contents: info.data.printData.userDataList,
            templateData: info.data.printData.tempData,
          });
        }
      })
      .catch(() => playVoice(voiceData.错误));
  };

  @action public handleClearState = () => {
    this.clothesMaterialType = undefined;
    this.uniqueCode = '';
    this.otherProblem = '';
    this.uniqueCodeRef.current.focus();
    this.qualityTestingData = {};
    this.selectQualityItem = [];
  };

  @observable public otherProblem = '';

  @action public handleOtherProblemChange = (event) => {
    this.otherProblem = event.target.value;
  };

  @observable public selectQualityItem: string[] = [];

  @action public handleItemClick = (value: string) => {
    if (this.showTestingView) {
      if (this.selectQualityItem.includes(value)) {
        this.selectQualityItem = this.selectQualityItem.filter((val) => val !== value);
      } else {
        this.selectQualityItem.push(value);
      }
    }
  };

  @action public handleReset = () => {
    this.selectQualityItem = [];
    this.otherProblem = '';
    this.clothesMaterialType = undefined;
  };

  @observable public isTesting = false;

  @computed
  public get disabledQualityGoods(): boolean {
    if (!this.showTestingView || this.clothesMaterialType == undefined) {
      return true;
    }

    return !(this.selectQualityItem.length === 0 && this.otherProblem.length === 0);
  }

  @computed
  public get disabledShortageGoods(): boolean {
    if (!this.showTestingView || this.clothesMaterialType == undefined) {
      return true;
    }

    return this.selectQualityItem.length === 0 && this.otherProblem.length === 0;
  }

  @action public handleQualityTesting = (inspection: boolean, uniqueCode: string): void => {
    const requestQualityTesting = (): Promise<void> => {
      return request<BaseData<{ userDataList: any[]; tempData: any; }>>({
        url: '/api/cloud/wms/rest/quality/cloud/check',
        method: 'post',
        data: {
          inspection,
          uniqueCode,
          item: toJS(this.selectQualityItem),
          otherProblem: this.otherProblem,
          cotton: this.clothesMaterialType,
        },
      })
        .then((info) => {
          message.success('质检完成');

          if (info?.data?.userDataList && info?.data?.tempData && this.paramsConfig.qualityPrintReaSon) {
            printHelper.toggleToLodop();
            printHelper.print({
              preview: false,
              contents: info.data.userDataList,
              templateData: info.data.tempData,
            });
          }

          this.handleClearState();
        });
    };

    if (!inspection && this.qualityTestingData.businessType === UNICODE_BELONG_TYPE.afterSale) {
      Modal.confirm({
        title: '请确认退货信息',
        content: (
          <>
            <h3>
              售后退回信息:
              {this.qualityTestingData.afterSaleNo}
            </h3>
            <h3>
              货主:
              {this.qualityTestingData.afterSaleOwner}
            </h3>
          </>
        ),
        onOk: requestQualityTesting,
      });
    } else {
      this.isTesting = true;
      requestQualityTesting()
        .finally(() => this.isTesting = false);
    }
  };

  @action public getQualityItem = () => {
    request<BaseData<QualityItemData>>({ url: '/api/cloud/wms/rest/quality/cloud/queryUniqueItem' })
      .then((info) => {
        [
          'BasicProductCheckEnum',
          'OutwardAppearanceEnum',
          'WormOutOrFluidEnum',
          'OtherEnum',
          'HatOrOxTendonEnum',
          'PackOrBarcodeEnum',
        ].forEach((key) => {
          if (info.data?.[key]) {
            this.qualityItemData[key] = objToDict(info.data[key]);
          }
        });
      });
  };
}
