export const ENUM_CLOTHES_MATERIAL_TYPE = {
  cotton: {
    value: 1,
    label: '棉衣',
  },
  notCotton: {
    value: 0,
    label: '非棉衣',
  },
};
