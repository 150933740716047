export interface QualityItemData {
  [key: string]: {[key: string]: string; };
}

export interface QualityTestingData {
  pic?: string;
  skuNo?: string;
  color?: string;
  size?: string;
  uniqueCode?: string;
  cotton?: number;
  businessType?: number;
  afterSaleNo?: string;
  afterSaleOwner?: string;
  printData?: {
    tempData?: any;
    userDataList?: any[];
  };
}

export enum UNICODE_BELONG_TYPE {
  afterSale = 10
}

export interface ParamsConfig {
  qualityPrint: boolean;
  qualityPrintReaSon: boolean;
}
