import classnames from 'classnames';
import { getStaticResourceUrl } from 'egenie-common';
import _ from 'lodash';
import React from 'react';
import styles from './goodsWrapper.less';

export interface GoodsWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  data: { skuNo?: string; color?: string; size?: string; pic?: string; };
}

export const GoodsWrapper: React.FC<GoodsWrapperProps> = ({
  className = '',
  style = {},
  data = {},
}) => {
  return _.isEmpty(data) ? (
    <div
      className={classnames(styles.goodsWrapper, className)}
      style={style}
    >
      <header className={styles.placeholder}>
        SKU编码
      </header>
      <header className={styles.placeholder}>
        颜色
        &nbsp;
        尺码
      </header>
      <div className={styles.placeholderImgContainer}>
        <img src={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/qualityTestEmpty.png')}/>
        <header>
          商品图片
        </header>
      </div>
    </div>
  ) : (
    <div className={styles.goodsWrapper}>
      <header>
        {data.skuNo}
      </header>
      <header>
        {data.color}
        &nbsp;
        {data.size}
      </header>
      <img src={data.pic}/>
    </div>
  );
};
